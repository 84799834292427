<template>
  <div class="news">
    <div class="banner">
      <div class="banner_text">
        <h6 class="banner_title">新闻资讯</h6>
        <div class="line"></div>
        <p class="banner_content">NEWS</p>
      </div>
    </div>
    <div class="container">
      <div class="row" :data="tableData">
        <div class="col-sm-4 col-xs-6 " v-for="(item, index) in list" :key="index" style="margin: 20px 0px;">
          <div class="card">
            <div class="card_date">
              <img :src="item.imgPhoto"  class="card-img-top" alt="..." >
<!--              <div class="date">-->
<!--                <span style="font-size: 20px;">12</span>-->
<!--                <p style="font-size:14px;color:#B7B7B7">2023</p>-->
<!--              </div>-->
            </div>
            <div class="card-body">
              <h5 class="card-title">{{ item.title }}</h5>
<!--              <p class="card-text">{{ item.article }}</p>-->
              <p class="card-text"><small class="text-muted" @click="toPath(item)">MORE <img
                    src="../assets/arrow.png"></small></p>
            </div>
          </div>
        </div>
      </div>

      <div class="pageCount">
        <el-pagination background layout="prev, pager, next" :page-size="preView" @current-change="getList"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
// import {dataList} from "@/api/database";

export default {
  data() {
    return {
      pageCount: 1,
      total: 0,
      preView: 10,
      tableData: [],
      list: [
        {
          no:1,
          title: "数据分析SPSS篇——独立样本t检验",
          imgPhoto: require('../assets/newsImages/new1.jpg')
        },
        {
          no:2,
          title: "一篇文章！让你知道“卡方和Fisher怎么选”",
          imgPhoto: require('../assets/newsImages/new2.png')
        },
        {
          no:3,
          title: "发SCI必看！95%置信区间估计基本方法和软件操作",
          imgPhoto: require('../assets/newsImages/new3.jpg')
        },
        {
          no:4,
          title: "信我！！关于泊松回归看这一篇就够了！！！",
          imgPhoto: require('../assets/newsImages/new4.jpg')
        },
        {
          no:5,
          title: "临床常用SEER数据库使用指南【超详细版】",
          imgPhoto: require('../assets/newsImages/new5.png')
        },
        {
          no:6,
          title: "如何从“0”开始构建研究需要的CRF",
          imgPhoto: require('../assets/newsImages/new6.png')
        },
        {
          no:7,
          title: "力推！！！专为个人医生设计的免费科研平台",
          imgPhoto: require('../assets/newsImages/new7.jpg')
        },
        {
          no:8,
          title: "高分综述：预测模型（列线图），并不是表面上看到的那么简单",
          imgPhoto: require('../assets/newsImages/new8.jpg')
        },
        {
          no:9,
          title: "速看！单因素方差分析含SPSS篇",
          imgPhoto: require('../assets/newsImages/new9.jpg')
        },
      ]
    }
  },
  methods: {
    toPath(item) {
      // this.$router.push('/NewsDetail');
      // 将微信链接替换成你需要打开的链接
      let wechatLink = '';
      switch (item.no) {
        case 1:
          wechatLink = 'https://mp.weixin.qq.com/s/Wx63tG9-qq14VWlsW-7CXQ';
          break;
        case 2:
          wechatLink = 'https://mp.weixin.qq.com/s/ha8_X-eUmFAbWFM1hYiJ-Q';
          break;
        case 3:
          wechatLink = 'https://mp.weixin.qq.com/s/f6VGkW1m7gxult9Hnhv1-w';
          break;
        case 4:
          wechatLink = 'https://mp.weixin.qq.com/s/Mv4STDveuLnrG8_AAWgI5w';
          break;
        case 5:
          wechatLink = 'https://mp.weixin.qq.com/s/gCj8jgpqafpGCe5kvlkTgg';
          break;
        case 6:
          wechatLink = 'https://mp.weixin.qq.com/s/pdQC2EYl_yRxyBHTV3NKCA';
          break;
        case 7:
          wechatLink = 'https://mp.weixin.qq.com/s/IxP6aC_xDI93pmTvu4Ga7g';
          break;
        case 8:
          wechatLink = 'https://mp.weixin.qq.com/s/MSVjHMk4lskVNSC16pcN9A';
          break;
        case 9:
          wechatLink = 'https://mp.weixin.qq.com/s/1yU0B8EvO3o0lAda45cvvg';
          break;
        default:
          return 'https://example.com/defaultLink';
      }



      // 打开新的浏览器窗口并加载微信链接
      window.open(wechatLink, '_blank');
    },
    getList() {
      // dataList({
      //   pageNum,
      //   patientName: this.searchVal,
      //   pageSize: this.preView
      // }).then((res) => {
      //   if (res.code == 200) {
      //     this.tableData = res.rows;
      //     this.total = res.total;
      //   }
      // })
    },
  }

}


</script>
<style scoped lang="less">
/* 移动端样式 */
@media screen and (max-width: 767px) {
  .banner {
      width: 100%;
      height: 320px;
      background-image: url(../assets/news_phone.jpg);
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;

      .banner_text {
        width: 385px;
        height: 200px;
        text-align: center;

        .banner_title {
          font-size: 48px;
        }

        .banner_content {
          font-size: 20px;
          color: #333333
        }

        .line {
          position: relative;
          top: 2%;
          left: 44%;
          width: 30px;
          height: 2px;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
          // 线条粗细
          border: 1px solid black;
          margin: 24px 0px;
        }

      }
    }
    .text-muted {
    cursor: pointer;
  }
}

/* PC端样式 */
@media screen and (min-width: 768px) {
  .news{
    height: 1625px;
  }
  .card-img-top{
    width: 350px;
    height: 150px;
  }
  .banner {
      width: 100%;
      height: 600px;
      background-image: url(../assets/news_banner.jpg);
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      .banner_text {
        width: 385px;
        height: 200px;
        text-align: center;

        .banner_title {
          font-size: 48px;
        }

        .banner_content {
          font-size: 20px;
          color: #333333;
        }

        .line {
          position: relative;
          top: 2%;
          left: 44%;
          width: 30px;
          height: 2px;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
          // 线条粗细
          border: 1px solid black;
          margin: 24px 0px;
        }

      }
    }

    .card-body {
      background-color: #F4F4F4;
      .card-title{
        height: 50px;
      }
    }
    .card_date {
    position: relative;

    .date {
      width: 62px;
      height: 60px;
      background-color: #fff;
      text-align: center;
      position: absolute;
      bottom: 1px;
      left: 0;
    }
  }
  .text-muted {
    cursor: pointer;
  }
  }

 

</style >